export const cs = {
  name: 'gregorian_cs',
  months: [
    ['leden', 'led'],
    ['únor', 'úno'],
    ['březen', 'bře'],
    ['duben', 'dub'],
    ['květen', 'kvě'],
    ['červen', 'čvn'],
    ['červenec', 'čvc'],
    ['srpen', 'srp'],
    ['září', 'zář'],
    ['říjen', 'říj'],
    ['listopad', 'lis'],
    ['prosinec', 'pro'],
  ],
  weekDays: [
    ['sobota', 'so'],
    ['neděle', 'ne'],
    ['pondělí', 'po'],
    ['úterý', 'út'],
    ['středa', 'st'],
    ['čtvrtek', 'čt'],
    ['pátek', 'pá'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'am'],
    ['PM', 'pm'],
  ],
}

export const ar = {
  name: 'ar',
  months: [
    ['يناير', 'يناير'],
    ['فبراير', 'فبراير'],
    ['مارس', 'مارس'],
    ['أبريل', 'أبريل'],
    ['مايو', 'مايو'],
    ['يونيو', 'يونيو'],
    ['يوليو', 'يوليو'],
    ['أغسطس', 'أغسطس'],
    ['سبتمبر', 'سبتمبر'],
    ['أكتوبر', 'أكتوبر'],
    ['نوفمبر', 'نوفمبر'],
    ['ديسمبر', 'ديسمبر'],
  ],
  weekDays: [
    ['السّبت', 'السّبت'],
    ['الأحد', 'الأحد'],
    ['الإثنينِ', 'الإثنينِ'],
    ['الثلاثاء', 'الثلاثاء'],
    ['الأربعاء', 'الأربعاء'],
    ['الخميس', 'الخميس'],
    ['الجمعة', 'الجمعة'],
  ],

  digits: ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'],
  meridiems: [
    ['قبل الظهر', 'ق.ظ'],
    ['بعد الظهر', 'ب.ظ'],
  ],
}

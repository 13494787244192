import { ForwardRefRenderFunction, forwardRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { DatesFormValues } from 'src/ui/hooks/useDatesRangeForm'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import { DatePicker } from 'src/ui/components/molecules/DatePicker'

const DatePickerFormWithRef: ForwardRefRenderFunction<{
  set?: (key: string, value: number) => void
}> = ({}, ref) => {
  const { setValue, clearErrors, formState, getFieldState, watch } =
    useFormContext<DatesFormValues>()
  const checkIn = watch('dates.checkIn')
  const checkOut = watch('dates.checkOut')

  const languageConfig = useLanguageConfig()
  const locale = languageConfig.properties.culture

  const error = getFieldState('dates', formState).error

  const handleDatesChange = (checkIn: Date, checkOut?: Date) => {
    clearErrors()
    setFormValues(checkIn, checkOut)
  }

  const setFormValues = (checkIn: Date, checkOut: Date | undefined) => {
    setValue('dates', {
      checkIn,
      checkOut,
    })
  }

  return (
    <DatePicker
      ref={ref}
      checkIn={checkIn}
      checkOut={checkOut}
      error={error}
      locale={locale}
      onDatesChange={handleDatesChange}
    />
  )
}

export const DatePickerForm = forwardRef(DatePickerFormWithRef)

import { FC } from 'react'

interface Props {
  color: string
}

export const CheckIcon: FC<Props> = ({ color }) => (
  <path
    d="M21.0003 5L10.3227 19.2367C10.0793 19.5615 9.70691 19.7646 9.30206 19.7935C8.89721 19.8223 8.49978 19.674 8.21279 19.387L3.00028 14.1745"
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
  />
)

export default CheckIcon

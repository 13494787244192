import { FC } from 'react'

import { classNames } from 'src/ui/utils/classnames'

import styles from './Step.module.scss'

import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { Text, TextColor } from 'src/ui/components/atoms/Text'
import { StepId } from 'src/core/Shared/infrastructure/stepperManager'
import { CheckIcon } from 'src/ui/components/atoms/Icon/_icons/system/CheckIcon'

interface CircleProps {
  visited: boolean
  isCurrent: boolean
  completed: boolean
  step: StepId
  disabled: boolean
}

export const Circle: FC<CircleProps> = ({
  visited,
  isCurrent,
  completed,
  step,
  disabled = false,
}) => {
  const getColor = (): TextColor => {
    if (isCurrent) {
      return 'light' as TextColor
    }

    if (disabled) {
      return 'disabled' as TextColor
    }

    if (visited) {
      return 'secondary' as TextColor
    }

    return 'dark' as TextColor
  }

  const color = getColor()
  const iconColor =
    completed && !isCurrent && !visited ? 'icon-dark' : 'icon-light'

  return (
    <div
      className={classNames(
        styles.circle,
        isCurrent && styles.current,
        visited && styles.visited,
        disabled && styles.disabled,
      )}
    >
      {completed ? (
        <Icon size="s" color={iconColor} icon={CheckIcon} />
      ) : (
        <Text fontStyle={{ mobile: 's-500', laptop: 'm-500' }} color={color}>
          {step}
        </Text>
      )}
    </div>
  )
}

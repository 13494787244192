import { Cart } from 'src/core/Cart/domain/Cart.model'
import { CurrencyISOCode } from 'src/core/Shared/domain/Price.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { CartRepository } from 'src/core/Cart/domain/Cart.repository'
import { ReservationStorageRepository } from 'src/core/Reservation/infrastructure/Reservation.storage.repository'

export const getCartWhenIsDifferent =
  ({ cartRepository, reservationStorageRepository }: Dependencies) =>
  async (
    itineraryNumber: string,
    cart: Cart | undefined,
    token: string | undefined,
    currency: CurrencyISOCode,
  ) => {
    const reservationFields =
      reservationStorageRepository.getReservationFields(itineraryNumber)

    const isSameReservation = (cart: Cart) => {
      const isSameCurrency =
        isDefined(cart.prices.total.converted) &&
        cart.prices.total.converted.currency === currency
      const isSameItineraryNumber =
        cart.itineraryNumber === reservationFields.itineraryNumber

      return isSameCurrency && isSameItineraryNumber
    }

    if (isDefined(cart) && isSameReservation(cart)) {
      return Promise.resolve(undefined)
    }

    if (reservationFields.isPaid) {
      return Promise.resolve(undefined)
    }

    return await cartRepository.get(
      reservationFields.reservationId,
      reservationFields.itineraryNumber,
      token,
      currency,
    )
  }

interface Dependencies {
  cartRepository: CartRepository
  reservationStorageRepository: ReservationStorageRepository
}

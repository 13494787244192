import { FC } from 'react'

import { Text } from 'src/ui/components/atoms/Text'
import { useTrans } from 'src/ui/hooks/useTrans'

export const FormError: FC = () => {
  const { trans } = useTrans(['new-reservation'])

  return (
    <Text fontStyle="s-300" color="support-error">
      {trans('occupancy-form_guests_guests_children-age-error')}
    </Text>
  )
}

import { ComponentProps, forwardRef, ForwardRefRenderFunction } from 'react'
import { useFormContext } from 'react-hook-form'
import { SelectField } from 'src/ui/components'

const FormSelectFieldWithRef: ForwardRefRenderFunction<
  HTMLSelectElement,
  ComponentProps<typeof SelectField>
> = (props, ref) => {
  const { getFieldState, formState } = useFormContext()
  const error = getFieldState(props.name, formState).error

  return <SelectField {...props} error={error} ref={ref} />
}

export const FormSelectField = forwardRef(FormSelectFieldWithRef)

import { FC, MouseEventHandler } from 'react'
import { classNames } from 'src/ui/utils/classnames'
import styles from './NavIcon.module.scss'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { ChevronIcon } from 'src/ui/components/atoms/Icon/_icons/navigation/ChevronIcon'
import { useTrans } from 'src/ui/hooks/useTrans'

interface NavIconProps {
  disabled: boolean
  onClick?: MouseEventHandler
  icon?: 'left' | 'right'
}

export const NavIcon: FC<NavIconProps> = ({
  disabled,
  onClick,
  icon = 'right',
}) => {
  const { trans } = useTrans(['common'])

  return (
    <button
      className={classNames(
        styles.iconContainer,
        styles[icon],
        disabled && styles.disabled,
      )}
      disabled={disabled}
      onClick={event => {
        event.preventDefault()
        onClick?.(event)
      }}
      aria-label={
        icon === 'right'
          ? trans('calendar_next-month')
          : trans('calendar_previous-month')
      }
    >
      <Icon
        size="l"
        color={disabled ? 'text-disabled' : 'icon-dark'}
        icon={ChevronIcon}
        rotate={icon === 'left' ? 180 : undefined}
      />
    </button>
  )
}

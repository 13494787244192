import { FC } from 'react'

import { classNames } from 'src/ui/utils/classnames'

import styles from './Name.module.scss'

import { TextColor } from 'src/ui/components'

interface NameProps {
  label: string
  className: string | undefined
  disabled: boolean
  isCurrent: boolean
  visited: boolean
}

export const Name: FC<NameProps> = ({
  label,
  className,
  disabled,
  isCurrent,
  visited,
}) => {
  const getColor = (): TextColor => {
    if (isCurrent) {
      return '--b-color-text-dark' as TextColor
    }

    if (disabled) {
      return '--b-color-text-mid' as TextColor
    }

    if (visited) {
      return '--b-color-stepper-enabled' as TextColor
    }

    return '--b-color-text-dark' as TextColor
  }

  return (
    <span
      className={classNames(
        styles.name,
        className,
        isCurrent && styles.current,
        visited && styles.visited,
        disabled && styles.disabled,
      )}
      style={{ color: `var(${getColor()})` }}
      data-content={label}
    >
      {label}
    </span>
  )
}

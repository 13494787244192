import { FC } from 'react'

interface Props {
  color: string
}

export const ArrowIcon: FC<Props> = ({ color }) => (
  <>
    <path
      d="M4 11.75H20.25"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.75 5L4 11.75L10.75 18.5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default ArrowIcon

export const fr = {
  name: 'gregorian_fr',
  months: [
    ['janvier', 'janv.'],
    ['février', 'févr.'],
    ['mars', 'mars'],
    ['avril', 'avr.'],
    ['mai', 'mai'],
    ['juin', 'juin'],
    ['juillet', 'juil.'],
    ['août', 'août'],
    ['septembre', 'sept.'],
    ['octobre', 'oct.'],
    ['novembre', 'nov.'],
    ['décembre', 'déc.'],
  ],
  weekDays: [
    ['samedi', 'sa'],
    ['dimanche', 'di'],
    ['lundi', 'lu'],
    ['mardi', 'ma'],
    ['mercredi', 'me'],
    ['jeudi', 'je'],
    ['vendredi', 've'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'am'],
    ['PM', 'pm'],
  ],
}

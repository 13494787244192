import { FC, useLayoutEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styles from './GuestsForm.module.scss'

import { Flex } from 'src/ui/styles/objects/Flex'
import { Divider, Text } from 'src/ui/components'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { ChildrenAgeSelectors } from './ChildrenAgeSelectors'
import { useTrans } from 'src/ui/hooks/useTrans'
import { classNames } from 'src/ui/utils/classnames'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { getGuestsResumeTitle } from 'src/ui/i18n/getGuestsResumeTitle'
import { FormError } from './FormError'
import { ChevronIcon } from 'src/ui/components/atoms/Icon/_icons/navigation/ChevronIcon'
import { InfoIcon } from 'src/ui/components/atoms/Icon/_icons/interaction/InfoIcon'
import { ButtonLink } from 'src/ui/components/atoms/ButtonLink'
import { Counter } from 'src/ui/components/molecules/Counter'
import { AlertInline } from 'src/ui/components/molecules/AlertInline'
import { Translate } from 'src/ui/i18n/Translate'

interface Props {
  maxGuests: number
  maxChildren: number
  maxAdults: number
  maxChildAge: number
  showChildren: boolean
  roomIndex: number
  roomUniqueId: string
  numberOfUniqueIds: number
  expandedRoomId: string
  hasErrors: boolean
  onDeleteRoom: (uniqueId: string) => void
  onOpenRoom: (uniqueId: string) => void
}

export const GuestsForm: FC<Props> = ({
  maxGuests,
  maxChildren,
  maxAdults,
  maxChildAge,
  showChildren,
  roomIndex,
  roomUniqueId,
  numberOfUniqueIds,
  expandedRoomId,
  hasErrors,
  onDeleteRoom,
  onOpenRoom,
}) => {
  const { setValue, getValues, watch, unregister } = useFormContext()
  const [isVisibleContent, setIsVisibleContent] = useState(false)

  const roomRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const showRoomContent = expandedRoomId === roomUniqueId
    setIsVisibleContent(showRoomContent)

    if (showRoomContent && roomRef.current?.scrollIntoView) {
      roomRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [roomUniqueId, expandedRoomId])

  const { trans } = useTrans(['new-reservation'])

  const handleAdultsCounterMinusClick = () => {
    setValue(`adults#${roomUniqueId}`, getValues(`adults#${roomUniqueId}`) - 1)
  }

  const handleAdultsCounterPlusClick = () => {
    setValue(`adults#${roomUniqueId}`, getValues(`adults#${roomUniqueId}`) + 1)
  }

  const handleChildrenCounterMinusClick = () => {
    setValue(
      `children#${roomUniqueId}`,
      getValues(`children#${roomUniqueId}`) - 1,
    )

    unregister(
      `childrenAge#${roomUniqueId}_${getValues(`children#${roomUniqueId}`)}`,
    )
  }

  const handleChildrenCounterPlusClick = () => {
    setValue(
      `children#${roomUniqueId}`,
      getValues(`children#${roomUniqueId}`) + 1,
    )
  }

  const handleOpenRoom = () => {
    onOpenRoom(roomUniqueId)
  }

  const adults = watch(`adults#${roomUniqueId}`)
  const children = watch(`children#${roomUniqueId}`)

  const totalGuests = adults + children
  const isTotalGuestsReached = totalGuests >= maxGuests
  const isTotalAdultsReached = adults >= maxAdults
  const isTotalChildrenReached = children >= maxChildren
  const isMinimumAdultsReached = adults <= 1
  const isMinimumChildrenReached = children <= 0

  const getInitialAges = () =>
    Array.from({ length: children })
      .map((_, index) => getValues()[`childrenAge#${roomUniqueId}_${index}`])
      .filter(age => isDefined(age))

  return (
    <div
      className={classNames(
        styles.container,
        !isVisibleContent && styles.collapsedContainer,
        numberOfUniqueIds - 1 === roomIndex && styles.lastRoom,
        roomIndex === 0 && styles.firstRoom,
      )}
      ref={roomRef}
      onClick={e => {
        e.preventDefault()
        if (!isVisibleContent) {
          handleOpenRoom()
        }
      }}
    >
      <>
        <Flex justifyContent="space-between" alignItems="flex-end">
          <Text fontStyle="l-700" color="dark">
            {trans('occupancy-form_room_title', { roomNumber: roomIndex + 1 })}
          </Text>

          {!isVisibleContent && (
            <Icon
              icon={ChevronIcon}
              size="l"
              rotate={!isVisibleContent ? 90 : -90}
            />
          )}

          {isVisibleContent && numberOfUniqueIds > 1 && (
            <ButtonLink
              onClick={e => {
                e.preventDefault()

                if (numberOfUniqueIds === 1) return false

                onDeleteRoom(roomUniqueId)
              }}
            >
              {trans('occupancy-form_room_delete')}
            </ButtonLink>
          )}
        </Flex>

        {isVisibleContent && (
          <AlertInline
            type="neutral"
            text={trans('occupancy-form_max-ocupancy_message', { maxGuests })}
            className="mt-m mb-l"
          />
        )}

        {!isVisibleContent && (
          <Text fontStyle="s-500" color="mid">
            {getGuestsResumeTitle({
              adultsList: [adults],
              childrenList: [children],
              trans,
              showRooms: false,
              childrenAges: getInitialAges()?.toString().replaceAll(',', ', '),
            })}
          </Text>
        )}

        {isVisibleContent && (
          <>
            <div className={styles.guestItem}>
              <Flex direction="column">
                <Translate
                  i18nKey="new-reservation:occupancy-form_adults-with-limit"
                  values={{ max: maxAdults }}
                  components={{
                    regular: <Text fontStyle="m-300" />,
                    bold: <Text fontStyle="m-700" />,
                  }}
                />

                {showChildren && (
                  <Text fontStyle="s-300" color="mid">
                    {trans('occupancy-form_adults_info', {
                      maxChildAge: maxChildAge + 1,
                    })}
                  </Text>
                )}
              </Flex>
              <Counter
                labelPrefix={trans('guest-form_labelPrefix-adults')}
                counter={adults}
                isMinusDisabled={isMinimumAdultsReached}
                isPlusDisabled={isTotalAdultsReached || isTotalGuestsReached}
                onMinusClick={handleAdultsCounterMinusClick}
                onPlusClick={handleAdultsCounterPlusClick}
              />
            </div>

            {showChildren && (
              <>
                <Divider dir="horizontal" className="mt-m mb-m" />
                <div className={styles.guestItem}>
                  <Flex direction="column">
                    <Translate
                      i18nKey="new-reservation:occupancy-form_children-with-limit"
                      values={{ max: maxChildren }}
                      components={{
                        regular: <Text fontStyle="m-300" />,
                        bold: <Text fontStyle="m-700" />,
                      }}
                    />
                    <Text fontStyle="s-300" color="mid">
                      {trans('occupancy-form_children_info', {
                        maxChildAge: maxChildAge,
                      })}
                    </Text>
                  </Flex>
                  <Counter
                    labelPrefix={trans('guest-form_labelPrefix-children')}
                    counter={children}
                    isMinusDisabled={isMinimumChildrenReached}
                    isPlusDisabled={
                      isTotalChildrenReached || isTotalGuestsReached
                    }
                    onMinusClick={handleChildrenCounterMinusClick}
                    onPlusClick={handleChildrenCounterPlusClick}
                  />
                </div>

                <ChildrenAgeSelectors
                  totalChildren={children}
                  maxChildAge={maxChildAge}
                  formId="childrenAge"
                  roomUniqueId={roomUniqueId}
                />
              </>
            )}

            {!showChildren && isTotalGuestsReached && (
              <div className={styles.info}>
                <Icon size="l" icon={InfoIcon} />
                <Text fontStyle="s-300" color="mid">
                  {trans('occupancy-form_max-guests_adults-only_message', {
                    maxGuests,
                  })}
                </Text>
              </div>
            )}

            {hasErrors && <FormError />}
          </>
        )}
      </>
    </div>
  )
}

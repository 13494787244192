import { FC } from 'react'
import styles from './Step.module.scss'

import { Circle } from './Circle'

import { Name } from './Name'

import { classNames } from 'src/ui/utils/classnames'
import { StepId } from 'src/core/Shared/infrastructure/stepperManager'

interface StepProps {
  visited: boolean
  isCurrent: boolean
  completed: boolean
  disabled: boolean
  label: string
  stepId: StepId
  navigateToStep: (stepId: StepId) => void
  'data-testid': string
}

export const Step: FC<StepProps> = ({
  visited,
  isCurrent,
  completed,
  disabled,
  label,
  stepId,
  navigateToStep,
  'data-testid': testId,
}) => {
  const getStepClassName = () => {
    if (disabled) {
      return classNames(styles.wrapper, styles.disabled)
    }

    return classNames(styles.wrapper)
  }

  const getClassName = () => {
    if (completed) {
      return classNames(styles.completed)
    }
    return undefined
  }

  return (
    <div
      className={getStepClassName()}
      data-testid={testId}
      onClick={() => navigateToStep(stepId)}
    >
      <Circle
        step={stepId}
        visited={visited}
        isCurrent={isCurrent}
        completed={completed}
        disabled={disabled}
      />

      <Name
        className={getClassName()}
        disabled={disabled}
        visited={visited}
        isCurrent={isCurrent}
        label={label}
      />
    </div>
  )
}

export const en_gb = {
  name: 'gregorian_en',
  months: [
    ['January', 'Jan'],
    ['February', 'Feb'],
    ['March', 'Mar'],
    ['April', 'Apr'],
    ['May', 'May'],
    ['June', 'Jun'],
    ['July', 'Jul'],
    ['August', 'Aug'],
    ['September', 'Sep'],
    ['October', 'Oct'],
    ['November', 'Nov'],
    ['December', 'Dec'],
  ],
  weekDays: [
    ['Saturday', 'Sa'],
    ['Sunday', 'Su'],
    ['Monday', 'Mo'],
    ['Tuesday', 'Tu'],
    ['Wednesday', 'We'],
    ['Thursday', 'Th'],
    ['Friday', 'Fr'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'am'],
    ['PM', 'pm'],
  ],
}

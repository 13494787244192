export const getAgeOrDefault = (
  initialAges: number[] | undefined,
  index: number,
  maxChildAge: number,
  defaultValue: string,
) => {
  if (!initialAges) return defaultValue

  const age = initialAges[index]

  if (!age) return defaultValue
  if (age > maxChildAge) return defaultValue

  return age
}

export const tr = {
  name: 'gregorian_tr',
  months: [
    ['Ocak', 'Oca'],
    ['Şubat', 'Şub'],
    ['Mart', 'Mar'],
    ['Nisan', 'Nis'],
    ['Mayıs', 'May'],
    ['Haziran', 'Haz'],
    ['Temmuz', 'Tem'],
    ['Ağustos', 'Ağu'],
    ['Eylül', 'Eyl'],
    ['Ekim', 'Eki'],
    ['Kasım', 'Kas'],
    ['Aralık', 'Ara'],
  ],
  weekDays: [
    ['Cumartesi', 'Ct'],
    ['Pazar', 'Pz'],
    ['Pazartesi', 'Pt'],
    ['Salı', 'Sa'],
    ['Çarşamba', 'Ça'],
    ['Perşembe', 'Pe'],
    ['Cuma', 'Cu'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'am'],
    ['PM', 'pm'],
  ],
}

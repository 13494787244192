export const it = {
  name: 'gregorian_it',
  months: [
    ['gennaio', 'gen'],
    ['febbraio', 'feb'],
    ['marzo', 'mar'],
    ['aprile', 'apr'],
    ['maggio', 'mag'],
    ['giugno', 'giu'],
    ['luglio', 'lug'],
    ['agosto', 'ago'],
    ['settembre', 'set'],
    ['ottobre', 'ott'],
    ['novembre', 'nov'],
    ['dicembre', 'dic'],
  ],
  weekDays: [
    ['sabato', 'sa'],
    ['domenica', 'do'],
    ['lunedì', 'lu'],
    ['martedì', 'ma'],
    ['mercoledì', 'me'],
    ['giovedì', 'gi'],
    ['venerdì', 've'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'am'],
    ['PM', 'pm'],
  ],
}

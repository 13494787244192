import { Locale } from 'react-date-object'
import { ar } from 'public/locales/ar-ar/calendar'
import { cs } from 'public/locales/cs/calendar'
import { de } from 'public/locales/de/calendar'
import { en_gb } from 'public/locales/en-GB/calendar'
import { es_ar } from 'public/locales/es-AR/calendar'
import { es_mx } from 'public/locales/es-MX/calendar'
import { es } from 'public/locales/es-ES/calendar'
import { fr } from 'public/locales/fr/calendar'
import { it } from 'public/locales/it/calendar'
import { pt_br } from 'public/locales/pt-BR/calendar'
import { pt } from 'public/locales/pt-PT/calendar'
import { ru } from 'public/locales/ru/calendar'
import { tr } from 'public/locales/tr/calendar'
import { pl } from 'public/locales/pl/calendar'
import { sl } from 'public/locales/sl/calendar'
import { en_us } from 'public/locales/en-US/calendar'

export const locales = (locale: string): Locale => {
  switch (locale.toLowerCase()) {
    case 'ar-eg':
      return ar
    case 'cs-cz':
      return cs
    case 'de-de':
      return de
    case 'en-gb':
      return en_gb
    case 'es-ar':
      return es_ar
    case 'es-mx':
      return es_mx
    case 'es-es':
      return es
    case 'fr-fr':
      return fr
    case 'it-it':
      return it
    case 'pt-br':
      return pt_br
    case 'pt-pt':
      return pt
    case 'ru-ru':
      return ru
    case 'tr-tr':
      return tr
    case 'pl-pl':
      return pl
    case 'sl-si':
      return sl
    case 'en-us':
      return en_us
    default:
      return en_gb
  }
}

import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export const getTotalNights = (
  checkIn: Date | undefined,
  checkOut: Date | undefined,
) => {
  if (isUndefined(checkIn) || isUndefined(checkOut)) {
    return 0
  }

  if (checkIn === checkOut) {
    return 0
  }

  return datesManager.calculateNights(checkIn, checkOut)
}

import { FC } from 'react'

interface Props {
  color: string
}

export const MinusIcon: FC<Props> = ({ color }) => (
  <>
    <rect
      x="13"
      y="16.2"
      width="2"
      height="20"
      rx="1"
      transform="rotate(90 19.8 18.4)"
      fill={color}
    />
  </>
)

export default MinusIcon

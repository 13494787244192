export const de = {
  name: 'gregorian_de',
  months: [
    ['Januar', 'Jan.'],
    ['Februar', 'Feb.'],
    ['März', 'März'],
    ['April', 'Apr.'],
    ['Mai', 'Mai'],
    ['Juni', 'Juni'],
    ['Juli', 'Juli'],
    ['August', 'Aug.'],
    ['September', 'Sep.'],
    ['Oktober', 'Okt.'],
    ['November', 'Nov.'],
    ['Dezember', 'Dez.'],
  ],
  weekDays: [
    ['Samstag', 'Sa'],
    ['Sonntag', 'So'],
    ['Montag', 'Mo'],
    ['Dienstag', 'Di'],
    ['Mittwoch', 'Mi'],
    ['Donnerstag', 'Do'],
    ['Freitag', 'Fr'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'am'],
    ['PM', 'pm'],
  ],
}

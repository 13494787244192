import { CheckInCheckOut } from 'src/core/Shared/domain/CheckInCheckOut'
import { analytics } from 'src/core/Shared/infrastructure/analytics'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { GuestsFormValues } from 'src/ui/hooks/useGuestsForm'
import { CouponsFormValues } from 'src/ui/hooks/useCouponsForm'

export const useEditAvailabilityActions = () => {
  const { queryUtils, navigate } = useApplicationRouter()

  const modifyDates = async (dates: CheckInCheckOut) => {
    analytics.actions.shared.changeDates(dates)

    const query = queryUtils.buildDatesQuery(dates)
    await navigate.toAvailabilityWhenEdited(query)
  }

  const modifyGuests = async (guests: GuestsFormValues) => {
    analytics.actions.shared.changeGuests(guests)

    const query = queryUtils.buildGuestsQuery(guests)
    await navigate.toAvailabilityWhenEdited(query)
  }

  const modifyCoupon = async (coupon: CouponsFormValues | undefined) => {
    analytics.actions.shared.changeCoupon(coupon?.value)

    const couponQuery = queryUtils.buildCouponQuery(coupon)
    await navigate.toAvailabilityWhenEdited(couponQuery)
  }

  const modifyAvailability = async (
    dates: CheckInCheckOut,
    guests: GuestsFormValues,
    coupon: CouponsFormValues | undefined,
  ) => {
    analytics.actions.shared.changeGuests(guests)
    analytics.actions.shared.changeDates(dates)
    analytics.actions.shared.changeCoupon(coupon?.value)

    const query = queryUtils.buildEditAvailabilityCriteriaQuery(
      guests,
      dates,
      coupon,
    )

    await navigate.toAvailabilityWhenEdited(query)
  }

  return {
    modifyDates,
    modifyGuests,
    modifyCoupon,
    modifyAvailability,
  }
}

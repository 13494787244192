import { FC } from 'react'
import { classNames } from 'src/ui/utils/classnames'
import styles from './Counter.module.scss'

import { Flex } from 'src/ui/styles/objects/Flex'
import { Text } from 'src/ui/components'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import MinusIcon from 'src/ui/components/atoms/Icon/_icons/interaction/MinusIcon'
import CloseIcon from 'src/ui/components/atoms/Icon/_icons/interaction/CloseIcon'

interface Props {
  counter: number
  labelPrefix: string
  readOnly?: boolean
  isMinusDisabled?: boolean
  isPlusDisabled?: boolean
  onMinusClick: () => void
  onPlusClick: () => void
}

export const Counter: FC<Props> = ({
  counter,
  labelPrefix,
  isMinusDisabled,
  isPlusDisabled,
  readOnly,
  onMinusClick,
  onPlusClick,
}) => {
  const { trans } = useTrans(['common'])

  return (
    <Flex alignItems="center" justifyContent="center">
      <button
        aria-label={trans('counter_label-subtraction', { labelPrefix })}
        className={classNames(styles.button)}
        disabled={readOnly || isMinusDisabled}
        onClick={onMinusClick}
        type="button"
      >
        <Icon
          color={readOnly || isMinusDisabled ? 'icon-disabled' : 'icon-dark'}
          size="m"
          icon={MinusIcon}
        ></Icon>
      </button>
      <Text
        fontStyle="m-500"
        color={
          readOnly || (isPlusDisabled && isMinusDisabled) ? 'disabled' : 'dark'
        }
        as="p"
        className={styles.counterNumber}
        data-testid={`${labelPrefix} counter`}
      >
        {counter}
      </Text>
      <button
        aria-label={trans('counter_label-addition', { labelPrefix })}
        className={classNames(styles.button)}
        disabled={readOnly || isPlusDisabled}
        onClick={onPlusClick}
        type="button"
      >
        <Icon
          color={readOnly || isPlusDisabled ? 'icon-disabled' : 'icon-dark'}
          size="m"
          icon={CloseIcon}
          rotate={45}
        ></Icon>
      </button>
    </Flex>
  )
}

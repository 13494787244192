import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Translator } from 'src/ui/hooks/useTrans'

interface GetGuestsResumeTitle {
  adultsList: number[]
  childrenList: number[]
  trans: Translator
  showRooms?: boolean
  childrenAges?: string
  showRoomsFirst?: boolean
}

export const getGuestsResumeTitle = ({
  adultsList,
  childrenList,
  trans,
  showRooms = true,
  childrenAges,
  showRoomsFirst = false,
}: GetGuestsResumeTitle): string => {
  const hasChildren = childrenList.length > 0 && sumInAllRooms(childrenList) > 0

  if (!hasChildren && !showRooms) {
    return trans('new-reservation:occupancy-form_room_guests_resume-adults', {
      adults: sumInAllRooms(adultsList),
    })
  }

  if (!hasChildren && showRooms) {
    return trans(
      !showRoomsFirst
        ? 'new-reservation:occupancy-form_room_guests_resume-adults-with-rooms'
        : 'new-reservation:occupancy-form_room_guests_resume-adults-with-rooms_first',
      {
        adults: sumInAllRooms(adultsList),
        rooms: adultsList.length,
      },
    )
  }

  if (isDefined(childrenAges)) {
    return trans(
      'new-reservation:occupancy-form_room_guests_resume-adults-with-childrenAges',
      {
        adults: sumInAllRooms(adultsList),
        children: sumInAllRooms(childrenList),
        childrenAges: childrenAges,
      },
    )
  }

  if (showRooms) {
    return trans(
      !showRoomsFirst
        ? 'new-reservation:occupancy-form_room_guests_resume-adults-with-children-and-rooms'
        : 'new-reservation:occupancy-form_room_guests_resume-adults-with-children-and-rooms_first',
      {
        adults: sumInAllRooms(adultsList),
        children: sumInAllRooms(childrenList),
        rooms: adultsList.length,
      },
    )
  }

  return trans(
    'new-reservation:occupancy-form_room_guests_resume-adults-with-children',
    {
      adults: sumInAllRooms(adultsList),
      children: sumInAllRooms(childrenList),
    },
  )
}

const sumInAllRooms = (value: number[] = [0]): number =>
  value.reduce((prev, current) => prev + current, 0)

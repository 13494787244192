import { Text, Button, Link, Modal } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'
import { useTrans } from 'src/ui/hooks/useTrans'
import { createModal } from 'src/ui/hooks/useModal'
import { Translate } from 'src/ui/i18n/Translate'

interface Props {
  callCenterCountryCode: string
  callCenterPhone: string
  callCenterFreePhone: string
  onHide: () => void
}

export const UserLevelNotFoundWarningModal = createModal(
  ({
    callCenterCountryCode,
    callCenterPhone,
    callCenterFreePhone,
    onHide,
  }: Props) => {
    const { trans } = useTrans(['common'])

    return (
      <Modal
        type="transactional"
        size="m"
        data-quantum-modal="UserLevelNotFoundWarningModal"
      >
        <section>
          <Text
            fontStyle="xl-700"
            color="dark"
            as="h1"
            centered
            className="mb-m"
          >
            {trans('no-user-level-warning_title')}
          </Text>
          <Text fontStyle="m-700" color="dark" as="p" centered className="mb-m">
            {trans('no-user-level-warning_subtitle')}
          </Text>
          <Text
            fontStyle="m-300"
            color="dark"
            as="div"
            centered
            className="mb-xl"
          >
            {trans('no-user-level-warning_message')}{' '}
            <Translate
              i18nKey="no-user-level-warning_call-us"
              values={{ phone: callCenterPhone }}
              components={{
                regular: (
                  <Text
                    as="p"
                    fontStyle={{ mobile: 's-300', desktop: 'm-300' }}
                  />
                ),
                bold: (
                  <Link
                    data-data-phone={callCenterCountryCode}
                    link={`tel:${callCenterPhone}`}
                    variant="secondary"
                  >
                    {`${callCenterFreePhone}${callCenterFreePhone}`}
                  </Link>
                ),
              }}
            />
          </Text>
          <Flex justifyContent="center">
            <Button
              size={{
                mobile: 'fullWidth',
                laptop: 'large',
              }}
              onClick={onHide}
            >
              {trans('no-user-level-warning_continue')}
            </Button>
          </Flex>
        </section>
      </Modal>
    )
  },
)

import { FC } from 'react'

interface Props {
  color: string
}

export const GastroIcon: FC<Props> = ({ color }) => (
  <>
    <path
      d="M3.37427 9.87158V22.24"
      stroke={color}
      strokeWidth="0.85897"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.8628 15.3687H21.9269C22.3057 15.3687 22.6131 15.0622 22.6141 14.6834C22.6214 11.1735 22.6397 6.93434 21.1858 3.42445C21.0604 3.12148 20.7384 2.94931 20.4168 3.01327C20.0952 3.07723 19.8636 3.3595 19.8637 3.68739V22.24"
      stroke={color}
      strokeWidth="0.85897"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 3.00043V8.49751C2 9.2565 2.61528 9.87178 3.37427 9.87178C4.13326 9.87178 4.74854 9.2565 4.74854 8.49751V3.00043M16.4298 9.21488C14.082 6.69463 10.1866 6.41479 7.50258 8.57355M6.80995 18.7743C8.05382 20.1084 9.79589 20.8659 11.6199 20.8659C13.4439 20.8659 15.186 20.1084 16.4298 18.7743"
      stroke={color}
      strokeWidth="0.85897"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default GastroIcon

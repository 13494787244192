import { useCallback, useEffect } from 'react'
import { useUser } from 'src/ui/contexts/UserContext'
import {
  isUndefined,
  isDefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { container } from 'src/core/Shared/_di'
import { UserLevel } from 'src/core/User/domain/User'
import { useModal } from 'src/ui/hooks/useModal'
import { UserLevelNotFoundWarningModal } from 'src/ui/views/_components/UserLevelNotFoundWarningModal'
import { useCallCenter } from 'src/ui/hooks/useCallCenter'

export const useUserLevelNotFoundWarning = () => {
  const { user } = useUser()
  const { showModal, hideModal } = useModal(UserLevelNotFoundWarningModal)
  const { callCenterCountryCode, callCenterPhone, callCenterFreePhone } =
    useCallCenter()

  const handleHide = useCallback(() => {
    hideModal()
    container
      .resolve('sessionStorageClient')
      .set('userLevelNotFoundWarningShowed', true)
  }, [hideModal])

  useEffect(() => {
    if (isUndefined(user)) {
      return
    }

    if (user.level !== UserLevel.NotFound) {
      return
    }

    const alreadyShowed = isDefined(
      container
        .resolve('sessionStorageClient')
        .get('userLevelNotFoundWarningShowed'),
    )
    if (alreadyShowed) {
      return
    }

    showModal({
      callCenterCountryCode,
      callCenterPhone,
      callCenterFreePhone,
      onHide: handleHide,
    })
  }, [showModal, user, handleHide])
}

import { Modal } from 'src/ui/components'
import { createModal } from 'src/ui/hooks/useModal'
import { Text } from 'src/ui/components/atoms/Text'
import { useTrans } from 'src/ui/hooks/useTrans'
import { GuaranteePolicy } from 'src/ui/views/_components/GuaranteePolicy'
import { CancellationPolicy } from 'src/ui/views/_components/CancellationPolicy'
import { PoliciesDetails } from 'src/core/Shared/domain/Policies.model'

interface Props {
  policiesDetails: PoliciesDetails
  hasToShowPrices: boolean
  hasIncludedExtras?: boolean
}

export const RatePoliciesModal = createModal(
  ({ policiesDetails, hasToShowPrices, hasIncludedExtras }: Props) => {
    const { trans } = useTrans(['new-reservation'])
    return (
      <Modal type="passive" size="xl" data-quantum-modal="RatePoliciesModal">
        <Text
          as="h2"
          fontStyle={{ mobile: 'xl-700', laptop: '2xl-700' }}
          color="dark"
          centered
        >
          {trans('rate-policies-modal_title')}
        </Text>
        <GuaranteePolicy
          guarantee={policiesDetails.guarantee}
          timeline="before-reservation"
          className="mt-l mb-m"
          hasToShowPrices={hasToShowPrices}
          hasIncludedExtras={hasIncludedExtras}
        />
        <CancellationPolicy
          cancellation={policiesDetails.cancellation}
          guarantee={policiesDetails.guarantee}
          forceExpandMoreInfo
          hasToShowPrices={hasToShowPrices}
        />
      </Modal>
    )
  },
)

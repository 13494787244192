import styles from './ChildrenAgeSelectors.module.scss'
import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { getAgeOrDefault } from './ChildrenAgeSelectors.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useTrans } from 'src/ui/hooks/useTrans'
import { FormSelectField } from 'src/ui/views/_components/FormSelectField'

interface Props {
  totalChildren: number
  maxChildAge: number
  formId: string
  roomUniqueId: string
}

export const ChildrenAgeSelectors: FC<Props> = ({
  totalChildren,
  maxChildAge,
  formId,
  roomUniqueId,
}) => {
  const { trans } = useTrans(['common'])

  const { register, getValues } = useFormContext()

  const getInitialAges = () => {
    const ages = []

    for (let index = 0; index < totalChildren; index++) {
      ages.push(getValues()[`${formId}${roomUniqueId}${index}`])
    }
    return ages.filter(age => isDefined(age))
  }

  if (totalChildren <= 0) return null

  const ageOptions = Array.from(Array(maxChildAge + 1).keys()).map(age => ({
    value: age.toString(),
    label: age.toString(),
  }))

  const defaultValue = trans('children-age-selector_default-value')

  return (
    <div className={styles.container}>
      {[...Array(totalChildren)].map((_, index) => (
        <FormSelectField
          {...register(`${formId}#${roomUniqueId}_${index}`, {
            value: getAgeOrDefault(
              getInitialAges(),
              index,
              maxChildAge,
              defaultValue,
            ),
            validate: {
              isNumber: value => !isNaN(value),
            },
          })}
          key={index}
          placeholder={defaultValue}
          options={ageOptions}
          ariaLabel={trans('children-age-selector_options_aria-label')}
        />
      ))}
    </div>
  )
}

import { FC } from 'react'
import { Text } from 'src/ui/components/atoms/Text'
import styles from './Day.module.scss'

interface DayProps {
  date: Date
  isDayBlocked: boolean
  ariaLabel?: string
}

export const Day: FC<DayProps> = ({ date, isDayBlocked, ariaLabel }) => {
  const currentDay = date.getDate()

  return (
    <Text
      fontStyle="s-700"
      color={isDayBlocked ? 'disabled' : undefined}
      className={isDayBlocked ? styles.disabledDate : undefined}
      ariaLabel={ariaLabel}
    >
      {currentDay}
    </Text>
  )
}

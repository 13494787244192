import { FC } from 'react'

import styles from './StepDivider.module.scss'

import { TextColor } from 'src/ui/components'

interface StepDividerProps {
  disabled: boolean
  visited: boolean
  canNavigateToNextStep: boolean
}

export const StepDivider: FC<StepDividerProps> = ({
  disabled,
  visited,
  canNavigateToNextStep,
}) => {
  const getColor = (): TextColor => {
    if (visited) {
      return '--b-color-stepper-enabled' as TextColor
    }

    if (canNavigateToNextStep) {
      return '--b-color-interactive-primary-enabled' as TextColor
    }

    if (disabled) {
      return '--b-color-interactive-primary-disabled' as TextColor
    }

    return '--b-color-text-dark' as TextColor
  }

  return (
    <div
      className={styles.divider}
      style={{ backgroundColor: `var(${getColor()})` }}
    />
  )
}

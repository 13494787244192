export const ru = {
  name: 'gregorian_ru',
  months: [
    ['января', 'янв.'],
    ['февраля', 'февр.'],
    ['марта', 'мар.'],
    ['апреля', 'апр.'],
    ['мая', 'мая'],
    ['июня', 'июня'],
    ['июля', 'июля'],
    ['августа', 'авг.'],
    ['сентября', 'сент.'],
    ['октября', 'окт.'],
    ['ноября', 'нояб.'],
    ['декабря', 'дек.'],
  ],
  weekDays: [
    ['суббота', 'сб'],
    ['воскресенье', 'вс'],
    ['понедельник', 'пн'],
    ['вторник', 'вт'],
    ['среда', 'ср'],
    ['четверг', 'чт'],
    ['пятница', 'пт'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'am'],
    ['PM', 'pm'],
  ],
}

export const weekStartDayIndex = (locale: string): number => {
  switch (locale.toLowerCase()) {
    case 'es-ar':
      return 0
    case 'es-mx':
      return 0
    case 'en-us':
      return 0
    default:
      return 1
  }
}

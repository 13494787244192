export const sl = {
  name: 'gregorian_sl',
  months: [
    ['Januar', 'Jan'],
    ['Februar', 'Feb'],
    ['Marec', 'Mar'],
    ['April', 'Apr'],
    ['Maj', 'Maj'],
    ['Junij', 'Jun'],
    ['Julij', 'Jul'],
    ['Avgust', 'Avg'],
    ['September', 'Sep'],
    ['Oktober', 'Okt'],
    ['November', 'Nov'],
    ['December', 'Dec'],
  ],
  weekDays: [
    ['Sobota', 'So'],
    ['Nedelja', 'Ne'],
    ['Ponedeljek', 'Po'],
    ['Torek', 'To'],
    ['Sreda', 'Sr'],
    ['Četrtek', 'Če'],
    ['Petek', 'Pe'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'am'],
    ['PM', 'pm'],
  ],
}

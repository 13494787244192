export const pl = {
  name: 'gregorian_pl',
  months: [
    ['Styczeń', 'Sty'],
    ['Luty', 'Lut'],
    ['Marzec', 'Mar'],
    ['Kwiecień', 'Kwi'],
    ['Maj', 'Maj'],
    ['Czerwiec', 'Cze'],
    ['Lipiec', 'Lip'],
    ['Sierpień', 'Sie'],
    ['Wrzesień', 'Wrz'],
    ['Październik', 'Paź'],
    ['Listopad', 'Lis'],
    ['Grudzień', 'Gru'],
  ],
  weekDays: [
    ['Sobota', 'Sb'],
    ['Niedziela', 'Nd'],
    ['Poniedziałek', 'Pn'],
    ['Wtorek', 'Wt'],
    ['Środa', 'Śr'],
    ['Czwartek', 'Cz'],
    ['Piątek', 'Pt'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'am'],
    ['PM', 'pm'],
  ],
}

import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import styles from './Footer.module.scss'
import { Button } from 'src/ui/components/atoms/Button'
import { Text } from 'src/ui/components/atoms/Text'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Flex } from 'src/ui/styles/objects/Flex'
import { useMedia } from 'src/ui/hooks/useMedia'
import { FC } from 'react'
import { Media } from 'src/ui/styles/objects/Media'

interface Props {
  nights: number
  errorMessage: string | undefined
  position: string
}

export const Footer: FC<Props> = ({ errorMessage, nights }) => {
  const { trans } = useTrans(['common'])
  const { media } = useMedia()

  return (
    <>
      <Media mobile tablet>
        {isDefined(errorMessage) ? (
          <Flex
            alignItems="center"
            justifyContent="flex-start"
            gap="xs"
            className="mt-m"
          >
            <Text
              fontStyle="s-300"
              color="support-error"
              className={styles.errorMessage}
            >
              {errorMessage}
            </Text>
          </Flex>
        ) : (
          <Flex justifyContent="flex-start" className="mt-m">
            <Text fontStyle="s-300" color="dark">
              {trans('date-picker_total-nights', { nights })}
            </Text>
          </Flex>
        )}
      </Media>

      <Media laptop desktop>
        <Flex
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          gap="l"
        >
          {isDefined(errorMessage) ? (
            <Flex alignItems="center" gap="xs">
              <Text fontStyle="s-300" color="support-error">
                {errorMessage}
              </Text>
            </Flex>
          ) : (
            <Text fontStyle="s-300" color="dark">
              {trans('date-picker_total-nights', { nights })}
            </Text>
          )}
          <div className={styles.divider} />
          <Button
            size={media === 'laptop' ? 'small' : 'large'}
            type="submit"
            data-testid="datesSubmitButton"
          >
            {trans('date-picker_accept')}
          </Button>
        </Flex>
      </Media>
    </>
  )
}

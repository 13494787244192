import { FC } from 'react'

interface Props {
  color: string
}

export const InfoIcon: FC<Props> = ({ color }) => (
  <path
    d="M12 15.6V12M12 8.4H12.009M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
    stroke={color}
    strokeWidth="1.01"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export default InfoIcon

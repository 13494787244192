export const pt_br = {
  name: 'gregorian_pt-br',
  months: [
    ['janeiro', 'jan'],
    ['fevereiro', 'fev'],
    ['março', 'mar'],
    ['abril', 'abr'],
    ['maio', 'mai'],
    ['junho', 'jun'],
    ['julho', 'jul'],
    ['agosto', 'ago'],
    ['setembro', 'set'],
    ['outubro', 'out'],
    ['novembro', 'nov'],
    ['dezembro', 'dez'],
  ],
  weekDays: [
    ['Sábado', 'Sá'],
    ['Domingo', 'Do'],
    ['Segunda-feira', 'Se'],
    ['Terça-feira', 'Te'],
    ['Quarta-feira', 'Qu'],
    ['Quinta-feira', 'Qu'],
    ['Sexta-feira', 'Se'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'am'],
    ['PM', 'pm'],
  ],
}
